import { GlobalStyles, Sheet } from '@mui/joy'
import '../../components/Assets/css/Nav/nav_default.css'
import { LogoBox } from '../Box/custom_box'

export default function NavBar() {
    return (
        <Sheet
            sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                width: '100vw',
                // height: 'var(--Header-height)',
                backgroundColor:'teal',
                zIndex: 9995,
                py: 1,
                px: 2,
                gap: 1,
                boxShadow: 'sm',
            }}
        >
        <GlobalStyles
            styles={(theme) => ({
            ':root': {
                '--Header-height': '52px',
                [theme.breakpoints.up('md')]: {
                '--Header-height': '0px',
                },
            },
            })}
        />
        {/* <IconButton
            onClick={() => toggleSidebar()}
            variant="outlined"
            color="neutral"
            size="sm"
        >
            <i data-feather="menu" />
        </IconButton> */}
        <LogoBox variant="plain" sx={{ boxShadow: 'none', mr: 'auto' }} />
        {/* <ColorSchemeToggle id={undefined} /> */}
    </Sheet>
        
    )
}