import axio from "axios";

export const request = axio.create({
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "http://162.0.237.160:4520/api",
  // baseURL: "http://192.168.5.51:8000/api",
  // baseURL: "https://api.poupanca.condominio.co.mz/api",
});

request.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token != null) {
    config.headers.Authorization = `Token ${token}`;
  }
  
  return config;
});