import { Warning } from "@mui/icons-material";
import { Alert, Box, Button, Grid, Typography } from "@mui/joy";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";


export function ErrorSimpleAlert({message}){
    return (
        <Box 
        sx={{ 
            // display:'flex',  
            textAlign:'center',
            alignContent:'center',
            alignItems:'center',
            width: '100%' }}>
            <Alert
                color="danger"
                size="md"
            >{message}</Alert>
        </Box>
    )
}

export function SuccessSimpleAlert({message}){
    return (
        <Box 
        sx={{ 
            // display:'flex',  
            textAlign:'center',
            alignContent:'center',
            alignItems:'center',
            width: '100%' }}>
            
            <Alert
                color="success"
                size="md"
            >
                <Grid spacing={0.5} sx={{ flexGrow: 1 }} >
                    <BsCheckCircleFill size={30} color="green" />
                    <Grid>
                    {message}
                    </Grid>
                </Grid>
                </Alert>
            
        </Box>
    );
}

export function InternalErrorAlertRetry({message, buttonFuction}){
    return(
        <Alert
        variant="soft"
        color="danger"
        invertedColors
        startDecorator={
            <Warning />
        }
        sx={{ alignItems: 'flex-start', gap: '1rem' }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography level="title-md">Ops, Algo deu errado</Typography>
          <Typography level="body-md">
            {message}
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            {/* <Button variant="outlined" size="sm">
            </Button> */}
            <Button onChange={() => buttonFuction()} variant="solid" size="sm">
              Tentar novamente
            </Button>
          </Box>
        </Box>
      </Alert>
    );
}