import React, { useEffect, useState } from 'react';
import Footer from '../../components/layout/footer/footer';
import Navbar from '../../components/layout/navbar/navbar';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import './terms.css'; 
import { request } from '../../settings/axio/request';
import { CircularProgress } from '@mui/joy';

function TermsConditions() {
  const [termsList, setTermsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true)
    request.get("v1/web/terms_and_conditions/").then((resp) => {
      setTermsList(resp.data)
      setIsLoading(false)

    }).catch((error) => {
      setIsLoading(false)
      console.log("Algo deu errado.")
    })
  }, [])


  return (
    <>
      <Navbar />
         <Box
  sx={{
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', 
    gap: 2, 
    marginTop: 10
  }}
>
  {(isLoading && <>
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
      <CircularProgress variant="outlined" />
    </Box> 
  Processando...
  </>) || termsList.map((itemlist, index) => (
    <Card key={index} variant="soft" sx={{ maxWidth: 600 }}>
      <CardContent>
        <Typography ><b>{itemlist.title}</b></Typography>
        <Typography variant="body2">{itemlist.subtitle}</Typography>
        <Typography >{itemlist.content}</Typography>
      </CardContent>
    </Card>
  ))}
</Box>
      <Footer />
    </>
  );
}

export default TermsConditions;


// termsList.map((itemlist, index) => (
//   <Card key={index} variant="soft" sx={{ maxWidth: 600 }}>
//     <CardContent>
//       <Typography >{itemlist.title}</Typography>
//       <Typography variant="body2">{itemlist.subtitle}</Typography>
//       <Typography >{itemlist.content}</Typography>
//     </CardContent>
//   </Card>
// ))