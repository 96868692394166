import { AspectRatio, Box, Button, 
    Card, 
    CardActions, 
    CardContent, 
    CircularProgress, 
    Divider, 
    FormControl, 
    FormLabel, 
    Input, 
    Option, 
    Select, 
    Stack,
    Typography } from "@mui/joy";
import { BsPersonBadge, BsPersonBadgeFill, BsPersonCircle } from "react-icons/bs";
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { StyleConstants } from "../../../components/Style/Constants/constants_style";
import { SimpleDatePicker } from "../../../components/DatePicker/custom_date_picker";
import { ImageBox, SimpleBox } from "../../../components/Box/custom_box";
import docIconFront from '../../../components/Assets/icons/doc_front_icon.png'
import { useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { request } from "../../../settings/axio/request";
import { ErrorSimpleAlert, InternalErrorAlertRetry, SuccessSimpleAlert } from "../../../components/Alerts/custom_alert";
import { UploadImageCard } from "../../../components/Card/custom_card";
import moment from 'moment';


export default function NuibCreationForm() {
    const { style } = StyleConstants();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(false);
    const [message, setMessage] = useState('');
    const [queryParameters] = useSearchParams()
    const [ error, setError ] = useState();
    const [ getFormData, setGetFormdata ] = useState(false)
    const [ submitFormError, setSubmitFormError ] = useState(false)
    const [ submitFormSucess, setSubmitFormSucess ] = useState(false)
    const [ showForm, setShowForm ] = useState(false)
    const [ isResident, setIsResident ] = useState(false)
    const [formInfo, setFormInfo] = useState({})
    const [docType, setDocType] = useState('BI')
    const [gender, setGender] = useState('')
    const [datebirth, setDatebirth] = useState()
    const [docImageFront, setDocImageFron] = useState(undefined)
    const [docImageBack, setDocImageBack] = useState(undefined)

    useEffect(() => {
        setIsLoading(true)
        var params = {otp_code:queryParameters.get('ref')}
        request.get('v2/otp/', { params }).then((resp) => {
            setFormInfo(resp.data)
            setMessage(resp.data['message'])
            setIsLoading(false)
            setShowForm(true)
            setError(false)
        }).catch ((err) => {
            setMessage("Não foi possível satisfazer o seu pedido tente novamente mais tarde.")
            setError(true)
            setIsLoading(false)
        })
        setIsLoading(false)
            setShowForm(true)
            setError(false)
        setGetFormdata(false)
    }, [getFormData])

    const tryAgain = () => {
        setGetFormdata(true)
    }
    
    function validateShowForm(){
        if(formInfo.exist && formInfo.is_valid){
            return true
        }
        setShowForm(false)
        return false

    }
    function getOtpInfo() {
        if(Object.keys(formInfo).length > 0){
            if(formInfo.have_account){
                return `${formInfo.otp_info.otp_from}: ${formInfo.otp_info.user_contact} - ${formInfo.user_info.full_name}`
            }else{
                return `${formInfo.otp_info.otp_from}: ${formInfo.otp_info.user_contact}`
            }
            
        }
        return 'Indisponível'
        
    }

    const submitForm = (event) => {
        event.preventDefault();
        setIsLoadingForm(true)
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        var data = {
            full_name: event.target.full_name.value,
            nuit: event.target.nuit.value,
            is_resident:isResident,
            date_of_birth: moment(datebirth).format("YYYY-MM-DD"),
            gender: gender,
            father_full_name: event.target.father_full_name.value,
            mother_full_name: event.target.mother_full_name.value,
            doc_number: event.target.doc_number.value,
            doc_type: docType,
            country_iso_code: event.target.country_iso_code.value,
            hometown_code:event.target.hometown_code.value,
            locality_code:event.target.location_code.value,
            neighborhood_name: event.target.neighborhood_name.value,
            address: event.target.address.value,
            doc_front:docImageFront,
            doc_front:docImageBack,
            memberContact:formInfo.otp_info.user_contact,
            client_number: "5",
        }
        request.post('v2/resident/additional_info/', data,config).then((resp) => {

            setMessage(resp.data['message'])
            setIsLoadingForm(false)
            setSubmitFormError(false)
            setSubmitFormSucess(true)
            setShowForm(false)
            setError(false)
        }).catch((e) => {

            setMessage("Não foi possível satisfazer o seu pedido tente novamente mais tarde.")
            setIsLoadingForm(false)
            setSubmitFormError(true)
            setShowForm(false)
            setError(false)
        })

    }

    return (    
        <Box sx={{
            flex: 1,
            maxWidth: 1200,
            width: '100%',
            mx: 'auto',
          }}>
            <form
                onSubmit={(event) => submitForm(event)}
            >
                <Stack 
                    direction="column"
                    justifyContent="center"
                    alignItems='center'
                    spacing={2}>
                    {isLoading ? <CircularProgress size="lg"/> : <>
                        {Object.keys(formInfo).length > 0 && !formInfo.exist ? <>
                            <ErrorSimpleAlert message={message} />
                        </> : <>{Object.keys(formInfo).length > 0 && !formInfo.is_valid && <>
                            <ErrorSimpleAlert message={message} />
                        </>}</>}
                        {error && <InternalErrorAlertRetry buttonFuction={tryAgain} message={message} />}
                        {submitFormError && <InternalErrorAlertRetry buttonFuction={tryAgain} message={message} />}
                        {submitFormSucess && <SuccessSimpleAlert message={message} />}
                        {showForm && <>

                            <SimpleBox content={getOtpInfo()} />   
                            <Card
                            variant="outlined"
                            sx={{
                                maxHeight: 'max-content',
                                maxWidth: '100%',
                                mx: 'auto',
                                // to make the demo resizable
                                overflow: 'auto',
                                resize: 'horizontal',
                            }}
                            >
                            <Typography level="title-lg" startDecorator={<InfoOutlined />}>
                                Dados necessarios para abertura de conta
                            </Typography>
                            <Divider inset="none" />
                            <CardContent
                                sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, minmax(80px, 1fr))',
                                gap: 1.5,
                                }}
                            >
                                <FormControl sx={{ gridColumn: '1/-1' }}>
                                <FormLabel>Nome Completo</FormLabel>
                                    <Input name="full_name" type="text" endDecorator={<BsPersonCircle color={style.iconColor} size={style.iconSize} />} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>NUIT</FormLabel>
                                    <Input 
                                        inputProps={{ maxLength: 9 }}
                                        name="nuit" 
                                        endDecorator={<BsPersonBadge color={style.iconColor} size={style.iconSize} />} />
                                </FormControl>
                                {/* <Grid spacing={0.5} sx={{ flexGrow: 1 }} container>
                                    <Grid xs={3}>
                                        <FormControl>
                                            <br/>
                                            <Select defaultValue="BI" >
                                                <Option value="dog">BI</Option>
                                                <Option value="cat">PST</Option>
                                                <Option value="fish">CDC</Option>
                                                <Option value="bird">Bird</Option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={9}>
                                    <FormControl>
                                        <FormLabel>Numero de documento</FormLabel>
                                        <Input endDecorator={<BsPersonVcard color={style.iconColor} size={style.iconSize} />} />
                                    </FormControl>
                                    </Grid>
                                </Grid> */}
                                <FormControl>
                                    <FormLabel>Numero de documento</FormLabel>
                                        <Input
                                            name="doc_number"
                                            // placeholder="Amount"
                                            // startDecorator={{ dollar: '$', baht: '฿', yen: '¥' }[currency]}
                                            defaultValue={Object.keys(formInfo).length > 0 ? formInfo.user_info.bi : ''}
                                            endDecorator={
                                                <Fragment>
                                                    <Divider orientation="vertical" />
                                                    <Select
                                                        variant="plain"
                                                        value={docType}
                                                        defaultValue={docType}
                                                        onChange={(_, value) => setDocType(value)}
                                                        slotProps={{
                                                        listbox: {
                                                            variant: 'outlined',
                                                        },
                                                        }}
                                                        sx={{'&:hover': { bgcolor: 'transparent' } }}
                                                    >
                                                        <Option value="BI">BI</Option>
                                                        <Option value="PST">PST</Option>
                                                        <Option value="CDC">CDC</Option>
                                                        <Option value="Bird">Bird</Option>
                                                    </Select>
                                                </Fragment>
                                            }
                                            // sx={{ width: 300 }}
                                        required/>
                                </FormControl>
                                <FormControl>
                                        <FormLabel>Masculino</FormLabel>
                                    <Select onChange={(e, value) => setGender(value)} defaultValue="Masculino" >
                                        <Option value="M">Masculino</Option>
                                        <Option value="F">Feminino</Option>
                                    </Select>
                                </FormControl>
                                <FormControl>
                                <FormLabel>Residência</FormLabel>
                                    <Select onChange={(e, value) => setIsResident(value)} name="resident" placeholder="Selecione uma das opcoes" >
                                        <Option value='R'>Residente</Option>
                                        <Option value='NR'>Não Residente</Option>
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ gridColumn: '1/-1' }}>
                                    <FormLabel>Data de nascimento</FormLabel>
                                    <SimpleDatePicker setdatebirth={setDatebirth} />
                                </FormControl>

                                <FormControl>
                                <FormLabel>Nome do pai</FormLabel>
                                    <Input name="father_full_name" type="text" endDecorator={<BsPersonCircle color={style.iconColor} size={style.iconSize} />} />
                                </FormControl>
                                <FormControl>
                                <FormLabel>Nome da mãe</FormLabel>
                                    <Input 
                                        name="mother_full_name" 
                                        type="text" 
                                        endDecorator={<BsPersonCircle color={style.iconColor} size={style.iconSize} />} 
                                        required/>
                                </FormControl>

                                <FormControl>
                                <FormLabel>Código ISO do país da nacionalidade</FormLabel>
                                    <Input 
                                        // defaultValue={'mz'}
                                        name="country_iso_code" 
                                        type="text"  
                                        required/>
                                </FormControl>
                                <FormControl>
                                <FormLabel>Código da Localidade de acordo com o INE</FormLabel>
                                    <Input 
                                        name="location_code" 
                                        type="text" 
                                        placeholder="Campo de caracter obrigatório para Nacionalidade Moçambicana"  
                                        required/>
                                </FormControl>

                                <FormControl sx={{ gridColumn: '1/-1' }}>
                                    <FormLabel>Código da Localidade de domicilio de acordo com o Instituto Nacional de Estatística</FormLabel>
                                    <Input name="hometown_code" required />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Bairro</FormLabel>
                                        <Input name="neighborhood_name" type="text"  />
                                    </FormControl>
                                <FormControl>
                                    <FormLabel>Endereço</FormLabel>
                                        <Input 
                                            name="address" 
                                            type="text" 
                                            placeholder="Nome da Rua,/Avenida, Numero, casa Nº, Q Nº" 
                                            required/>
                                </FormControl>
                                
                                <FormControl sx={{ gridColumn: '1/-1' }}>
                                <Typography
                                    level="body-sm"
                                    startDecorator={<InfoOutlined />}
                                    sx={{ alignItems: 'flex-start', maxWidth: 410, wordBreak: 'break-all' }}
                                >
                                    Carregue as fotos do seu documento, dados importantes para ajudar a validar a sua identidade.
                                </Typography>
                                <br/>
                                <Stack 
                                    direction='row'
                                    spacing={3}
                                    justifyContent="center"
                                    alignItems='center'
                                >
                                    
                                {/* <AspectRatio
                                        variant="outlined"
                                        ratio="4/3"
                                        sx={{
                                            width: 200,
                                            bgcolor: 'background.level2',
                                            borderRadius: 'md',
                                        }}
                                        >
                                        
                                        <Typography level="h2" component="div">
                                        <ImageBox img={docIconFront} />
                                        </Typography>
                                    </AspectRatio> */}
                                    <UploadImageCard 
                                        doc_props={(e)=>setDocImageFron(e.file)}
                                        icon={<BsPersonBadgeFill/>}
                                        add_args='Frontal' />
                                    <UploadImageCard 
                                        doc_props={(e)=>setDocImageBack(e.file)}
                                        icon={<BsPersonBadge/>}
                                        add_args='Traseira' />
                                </Stack>
                                </FormControl>
                                
                                {/* <Checkbox label="Save card" sx={{ gridColumn: '1/-1', my: 1 }} /> */}
                                <CardActions sx={{ gridColumn: '1/-1' }}>
                                <Button 
                                    loading={isLoadingForm}
                                    type="submit" 
                                    variant="solid" 
                                    style={{background:`${style.defaultColor}`}}>
                                    Submeter
                                </Button>
                                </CardActions>
                            </CardContent>
                            </Card>

                            </>}
                    </>}
                </Stack>
            </form>
            <Box component="footer" sx={{ py: 3 }}>
                <Typography level="body-xs" textAlign="center">
                © Speranza LTD {new Date().getFullYear()}
                </Typography>
            </Box>
        </Box>
    )
};
