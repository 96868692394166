import { Box, CssBaseline, CssVarsProvider, GlobalStyles } from "@mui/joy";
import NavBar from "../../../NavBar/nav_bar";


export default function DefaultLeyoutStyle({children}) {
    return (
        < >
            <GlobalStyles
                styles={(theme) => ({
                '[data-feather], .feather': {
                    color: `var(--Icon-color, ${theme.vars.palette.text.icon})`,
                    margin: 'var(--Icon-margin)',
                    fontSize: `var(--Icon-fontSize, ${theme.vars.fontSize.xl})`,
                    width: '1em',
                    height: '1em',
                },
                })}
            />
            <CssBaseline />
            <NavBar/>
            <Box
                component="main"
                className="MainContent"
                sx={(theme) => ({
                    '--main-paddingTop': {
                    xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
                    md: '5%',
                    // sm: '10%',
                    },
                    px: {
                    xs: 2,
                    md: 3,
                    },
                    pt: 'var(--main-paddingTop)',
                    pb: {
                    xs: 2,
                    sm: 2,
                    md: 3,
                    },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                    overflow: 'auto',
                })}
            >
            {children}
            </Box>
        </>
    )
};
