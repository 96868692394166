import { AspectRatio, Box } from "@mui/joy";
import logo from '../Assets/icons/form_icon.png'


export function SimpleBox (props) {
    const { content } = props;
    return (
        <Box
        sx={{
          mx: 'auto',
          width: '40%',
          p: 1,
          m: 1,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey',
          border: '1px solid',
          borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
          borderRadius: 3,
          textAlign: 'center',
          fontSize: '0.875rem',
          fontWeight: '700',
        }}
      >
        {!content ? 'Box content' : content}
      </Box>
    )
}

export function ImageBox({img}){
    return (
        <Box
            component="img"
            sx={{
                height: 25,
                width: 25,
                maxHeight: { xs: 65, md: 65 },
                maxWidth: { xs: 65, md: 65 },
            }}
            alt="The house from the offer."
            src={img}
        />
    )
}

export function LogoBox({ sx, ...props }){
 
    return (
        <AspectRatio
            ratio="1"
            variant="plain"
            {...props}
            sx={[
                {
                width: 40,
                borderRadius: 'sm',
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
        <div>
            <img src={logo}/>
        </div>
        </AspectRatio>
    )
}