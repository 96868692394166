import React from 'react'
import "./footer.css"


function Footer() {
  return (

    <footer class="footer">
    <div class="container">
    &copy; {new Date().getFullYear()} - Todos os direitos reservados
    </div>
</footer>
  )
}

export default Footer