import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import NavBar from './components/NavBar/nav_bar';
import Dashboard from './pages/dashboard/dashboard';
import NuibCreationForm from './pages/forms/BM/nuib_creation';
import DefaultLeyoutStyle from './components/Style/Constants/Leyout/leyout_stayle';
import OTPPage from './pages/otp';
import TermsConditions from './pages/terms/termsConditions';

function App() {
  return (
    <Router>
      {/* <NavBar/> */}
     
      <Routes>
      <Route path='/' exact element={<Dashboard/>}/>
      <Route path='/termos-e-condicoes/' exact element={<TermsConditions/>}/>
        <Route path='add-info/' exact element={
          <DefaultLeyoutStyle children={<NuibCreationForm />} />}/>
        <Route path='validate-otp/' exact element={
          <DefaultLeyoutStyle children={<OTPPage />} />}/>
      </Routes>
    </Router>
  );
}

export default App;
