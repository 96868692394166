import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormControl, FormLabel, Input, Link, Stack, Typography } from "@mui/joy";
import { request } from "../settings/axio/request";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorSimpleAlert } from "../components/Alerts/custom_alert";
import { StyleConstants } from "../components/Style/Constants/constants_style";
import CountdownTimer from "../components/time/Timer";
import Timer from "../components/time/Timer";


export default function OTPPage(){
    const { style } = StyleConstants();
    const [isLoading, setIsLoading] = useState(false);
    const [otpValidateLoading, setOtpValidateLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [queryParameters] = useSearchParams()
    const [ error, setError ] = useState();
    const [ getFormData, setGetFormdata ] = useState(false)
    const [ showForm, setShowForm ] = useState(false)
    const [ otpCode, setOtpCode ] = useState(false)
    const [formInfo, setFormInfo] = useState({})
    const [otpFormCode, setOTPFormCode] = useState(queryParameters.get('ref'));
    const [seconds, setSeconds] = useState(20);
    const [resetCountdown, setResetCountdown] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        // var params = {otp_code:queryParameters.get('ref')}
        // request.get('v2/otp/', { params }).then((resp) => {
        //     setFormInfo(resp.data)
        //     setMessage(resp.data['message'])
        //     setIsLoading(false)
        //     setShowForm(true)
        //     setError(false)
        // }).catch ((err) => {
        //     setMessage("Não foi possível satisfazer o seu pedido tente novamente mais tarde.")
        //     setError(true)
        //     setIsLoading(false)
        // })
        setIsLoading(false)
        setShowForm(true)
        setGetFormdata(false)

        const interval = setInterval(() => {
            if (seconds > 0) {
              setSeconds(seconds - 1);
            } else {
              clearInterval(interval); // Para o temporizador quando o tempo acabar.
            }
          }, 1000);
      
          return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado.
      

    }, [getFormData])

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
      };

    function validateShowForm(){
        if(formInfo.exist && formInfo.is_valid){
            return true
        }
        setShowForm(false)
        return false
    }


    const sendOPT = (event) => {

        event.preventDefault();
        setOtpValidateLoading(true)
        var data = {
            'otp_code':event.target.otp.value
        }
        request.post('v2/otp/validate/', data).then((resp) => {
            setOtpValidateLoading(false)
            setError(false)
            navigate(`/add-info/?ref=${event.target.otp.value}`)
        }).catch((err) =>{
            setOtpValidateLoading(false)
            setError(true);
            setMessage(err.response.data.message)
        })
    }
    return (
        <Box sx={{
            flex: 1,
            maxWidth: 1200,
            width: '100%',
            mx: 'auto',
          
          }}>  
          <form
                onSubmit={(event) => sendOPT(event)}
            >
                <Stack 
                    direction="column"
                    // justifyContent="center"
                    // alignItems='center'
                    spacing={2}>
                    {isLoading ? <CircularProgress size="lg"/> : <>
                        {Object.keys(formInfo).length > 0 && !formInfo.exist ? <>
                            <ErrorSimpleAlert message={message} />
                        </> : <>{Object.keys(formInfo).length > 0 && !formInfo.is_valid && <>
                            <ErrorSimpleAlert message={message} />
                        </>}</>}
                        {showForm ? <>
                            <Typography level="title-lg">
                            Insira o código enviado por SMS para o seu número.
                            </Typography>
                            {error && <ErrorSimpleAlert message={message} />}
                            {/* {submitFormError && <InternalErrorAlertRetry buttonFuction={tryAgain} message={message} />}
                            {submitFormSucess && <SuccessSimpleAlert message={message} />} */}
                            
                            <FormControl>
                                <FormLabel>Código OTP</FormLabel>
                                <Input
                                    inputProps={{ maxLength: 9 }}
                                    name="otp"
                                    type="number"
                                    isLoadingForm />
                            </FormControl>
                            {/* <Timer formInfo={formInfo} /> */}
                            <Button 
                                loading={otpValidateLoading}
                                type="submit" 
                                variant="solid" 
                                
                                style={{background:`${style.defaultColor}`}}>
                                Submeter
                            </Button>
                            
                        </> : <>{error && <ErrorSimpleAlert message={message} />}</>}
                    </>}
                </Stack>
            </form>
            <Box component="footer" sx={{ py: 3 }}>
                <Typography level="body-xs" textAlign="center">
                © Speranza LTD {new Date().getFullYear()}
                </Typography>
            </Box>
        </Box>
    )
}
