import { Box, Button, Card, Grid, Input, Typography, } from "@mui/joy";
import Link from '@mui/joy/Link';
import { useState } from "react";
import { StyleConstants } from "../Style/Constants/constants_style";
import { BsTrash3 } from "react-icons/bs";

const { style } = StyleConstants();

export function SimpleCard({ title }, children){
    return (
       <></>
    );
}

export function UploadImageCard({add_args, icon, doc_props,  sx, ...props }){
    const [file, setFile] = useState();
    function handleChange(e) {
        console.log(e.target.files[0].name);
        doc_props({
          file:e.target.files[0]
        })
        setFile(e.target.files[0]);
    }

    const deleteimage = () => {
      setFile(undefined)
      doc_props({
        file:undefined
      })
    }
    return (
        <Card
      variant="outlined"
      {...props}
      sx={[
        {
          width:40,
          height:'100%', 
          borderRadius: 'sm',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          px: 3,
          flexGrow: 1,
        },
        // ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box sx={{ p: 1, bgcolor: 'background.level1', borderRadius: '50%' }}>
        <Box
          sx={{
            width: 32,
            height: 32,
            borderRadius: '50%',
            bgcolor: 'background.level2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {icon}
        </Box>
      </Box>
      <Typography level="body-sm" textAlign="center">
        {file ? <Grid spacing={0.5} sx={{ flexGrow: 1 }} container>
          <Grid xs={9}>
          <Input 
            color="success"
            size="sm"
            variant="soft"
            defaultValue={file.name} disabled/>
          </Grid>
            <Grid xs={3}>
              <Button 
                onClick={deleteimage}
                size="sm"
                variant="soft" 
                color="danger"
                >
                  <BsTrash3/>
              </Button>
            </Grid>
        </Grid> : <><label style={{
            textDecoration:'underline',
            // padding: '6px 12px',
            cursor:'pointer'
        }}>
            <input onChange={(e) => handleChange(e)} style={{display:'none'}} type="file"/>
            Caregar documento
        </label>{' '}
        {add_args}<br /> PNG, JPG</>}
        
        
      </Typography>
    </Card>
    )
}