import React, { useState } from 'react';
import { styled } from "@mui/system";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import { BsCalendar2Date } from 'react-icons/bs';
import { StyleConstants } from '../Style/Constants/constants_style';
// import "moment/locale/es";

export function SimpleDatePicker({setdatebirth}, props){
    const { style } = StyleConstants();
    const [iconColor, setIconColor] = useState(style.iconColor)
    if(props.color){
        setIconColor(props.color)
    }
    const OpenPickerIcon = styled(BsCalendar2Date)({
        color:iconColor
      });
    
    return (
        <LocalizationProvider localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs}>
            <DatePicker
                format="DD-MM-YYYY"
                views={["day", "month", "year"]}
                onChange={(e) => setdatebirth(e.$d)}
                slots={{
                    openPickerIcon:OpenPickerIcon
                }}
                // DateTimeFormat={Intl.DateTimeFormat}  
                // locale='es'
            />
        </LocalizationProvider>
    )
}