

export const StyleConstants = function() {
    const componentsStyle = {
        iconSize: 20,
        iconColor: '#aaaaaa',
        defaultColor:'#008080',
        btnDeleteColor:'red'
    }
    return {style:componentsStyle}
}