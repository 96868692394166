import React from 'react'
import "./navbar.css"
function Navbar() {
  return (
    <div className='header'>
        <h1>Termos e condições de uso do *744# - Poupança</h1>
        </div>
  )
}

export default Navbar;